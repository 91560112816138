<template>
  <CCard v-if="!isLoading">
    <CCardHeader>
      <CIcon name="cil-notes"/>
      <h5 class="d-inline ml-2">
        {{ this.$route.meta.label }}
        <template v-if="isEdit">
          : <h3 class="d-inline"><CBadge color="secondary">{{ this.itemName }} | {{ this.restaurantName }}</CBadge></h3>
        </template>
        <template v-else>
          to <h3 class="d-inline"><CBadge color="secondary">{{ this.restaurantName }}</CBadge></h3>
          Restaurant
        </template>
      </h5>
      <div class="card-header-actions">
        <CButton color="dark" :to="this.prevRoute">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <alert-section
        :successAlertMessage="successAlertMessage"
        :dismissSecs="dismissSecs"
        :dismissSuccessAlert="dismissSuccessAlert"
        :errorAlertMessage="errorAlertMessage"
        :showErrorAlert="showErrorAlert"
      />

      <CForm>
        <CRow>
          <CCol col="6">
            <CSelect
              label="Menu Category"
              :options="availableCategories"
              :value.sync="$v.form.menu_cat.$model"
              placeholder="Please choose a category"
              custom
              :isValid="checkIfValid('menu_cat')"
              @update:value="changedMenuCategory"
            />
          </CCol>
          <CCol col="6">
            <CInput
              label="Custom ID"
              placeholder="Custom ID"
              :lazy="false"
              :value.sync="$v.form.custom_id.$model"
            />
          </CCol>

          <CCol col="12" v-if="menuNames">
            <div class="alert alert-success" role="alert">
              <h5 class="alert-heading">Menus in this category:</h5>
              {{ menuNames }}
            </div>
          </CCol>

          <CCol col="6">
            <CInput
              label="Name"
              placeholder="Name"
              :lazy="false"
              :value.sync="$v.form.menu_name.$model"
              :isValid="checkIfValid('menu_name')"
              invalidFeedback="This is a required field and must be at least 2 characters"
            />
          </CCol>
          <CCol col="6">
            <CInput
              label="Short Description"
              placeholder="Short Description"
              :lazy="false"
              :value.sync="$v.form.description.$model"
            />
          </CCol>

          <CCol col="6">
            <CInput
              label="Price"
              placeholder="0.00"
              :lazy="false"
              :value.sync="$v.form.price.$model"
              :isValid="checkIfValid('price')"
              invalidFeedback="This is a required field and must be numeric"
              type="number"
              step="0.01"
              pattern="^\d+(?:\.\d{1,2})?$"
            >
            <template #prepend-content><CIcon name="cil-euro"/></template>
            </CInput>
          </CCol>
          <CCol col="6">
            <CInput
              label="Takeout Price"
              placeholder="0.00"
              :lazy="false"
              :value.sync="$v.form.toPrice.$model"
              :isValid="checkIfValid('toPrice')"
              invalidFeedback="This is a required field and must be numeric"
              type="number"
              step="0.01"
              pattern="^\d+(?:\.\d{1,2})?$"
              v-show="this.usetoprice === true"
            >
            <template #prepend-content><CIcon name="cil-euro"/></template>
            </CInput>
          </CCol>
          <CCol col="6">
            <label class="d-block" style="font-weight: 500">Turn</label>
            <CSwitch
              labelOn="On"
              labelOff="Off"
              color="success"
              shape="pill"
              :lazy="false"
              :checked.sync="$v.form.eol.$model"
            />
          </CCol>

          <CCol md="6">
            <div class="d-flex flex-column">
              <CMedia
                :aside-image-props="{
                  src: this.previewMenuImage,
                  shape: 'rounded',
                  width: '100%',
                  height: '120px'
                }"
              >
                <p class="mb-2 font-weight-bold">Menu Image:</p>
                <CInput
                  :value.sync="$v.form.menu_image.$model"
                  type="hidden"
                  class="mb-0"
                />
                <!-- <CInputFile
                  :isValid="checkIfValid('menu_image')"
                  label = "Menu Image"
                  placeholder = "Chose an image..."
                  custom
                  @change = "onFileChanged"
                /> -->
                <label for="img" class="btn btn-outline-info mb-0">
                  <CIcon name="cil-file" /> Browse
                </label>

                <CInputFile
                  id="img"
                  :class="!checkIfValid('menu_image') ? 'is-invalid' : ''"
                  style="display: none"
                  @change = "onFileChanged"
                />

                <div v-if="!checkIfValid('menu_image')" class="invalid-feedback">
                  <div class="error" v-if="!$v.form.menu_image.required">
                    <span style="font-size: 1.2rem;">⚠️</span> Please select an image for the menu!
                  </div>
                </div>
              </CMedia>

              <CLink v-if="previewMenuImage"
                class="small mt-2 text-danger"
                @click="() => { this.selectedMenuImage = this.previewMenuImage = null; this.form.menu_image = ''; }"
              >
                <CIcon name="cil-trash" /> Remove Image
              </CLink>
            </div>
          </CCol>
        </CRow>

        <div class="sticky-search">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><CIcon name="cil-magnifying-glass"/></span>
            </div>
            <input v-model="search" type="search" class="form-control" placeholder="Search for menu groups..">
          </div>
        </div>

        <CRow>
          <CCol col="12" class="mt-2 table-responsive">
            <h5>Menu Groups (ingredients) Choice</h5>

            <table class="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"><CIcon name="cil-move" /></th>
                  <th scope="col" style="width: 16vw">Menu Groups</th>
                  <th scope="col">Ingredients</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="mg in allMenuGroups" :key="mg.id" :class="isSelectedMenuGroup(mg.id) ? 'selected-item': 'disable-item'">
                <!-- <tr v-for="mg in allMenuGroups" :key="mg.id" :class="mg.selected == 1 ? 'selected-item': 'disable-item'"> -->
                  <td class="enable-item">
                    <CInputCheckbox
                      addLabelClasses="custom-checkbox-label"
                      custom
                      @update:checked="(value) => selectMenuGroup(mg.id, value)"
                      :checked="isSelectedMenuGroup(mg.id)"
                    />
                    <!-- (v3) :checked="mg.selected == 1" -->
                    <!-- (v2) :checked="isSelectedMenuGroup(mg.id)" -->
                    <!-- (v1) :checked="selectedChilds.filter(child => child.menuExtGroupId == mg.id).length > 0" -->
                  </td>

                  <td class="move-item">
                    <CIcon
                      name="cil-move"
                      :data-smg-id="getSelectedMenuGroupId(mg.id)"
                      :data-id="mg.id"
                      class="align-bottom"
                      size="lg"
                    />
                  </td>

                  <td style="vertical-align: top !important;">
                    <h5>{{mg.Name}}</h5>
                    <span>{{mg.Notes}}</span>

                    <section class="border-top pt-3" v-if="isSelectedMenuGroup(mg.id)">
                      <div class="form-group">
                        <label class="mr-1">Type:</label>
                        <select @change="changeTypeListing(mg.id, $event)">
                          <option value='SLT'>ListBox</option>
                          <option value='CHK' :selected="selectedTypeListing(mg.id, 'CHK')">CheckBox</option>
                        </select>
                      </div>

                      <div class="form-group w-100">
                        <label class="d-block">Valid Days *</label>
                        <div class="day-group">
                          <div v-for="(day, index) in days" class="form-check form-check-inline mr-1 mt-1">
                            <CInputCheckbox
                              :label="day"
                              :checked="selectedValidDays(mg.id)?.includes(index)"
                              @update:checked="updateValidDays(mg.id, index)"
                            />
                          </div>
                        </div>
                        <small>* All days are valid if none is selected</small>
                      </div>
                    </section>
                  </td>

                  <td class="enable-item">
                    <ul>
                      <li v-for="child in mg.menu_ext_group_childs" v-bind:key="child.id">
                        <label class="for-checkbox">
                          <input
                            type="checkbox"
                            v-model="form.selectedChilds"
                            :value="{menuExtGroupId:mg.id, menuExtGroupChildId:child.id}"
                            @change="selectingChild(mg.id)"
                          />
                          {{ child.Name }} [{{ child.Price | toCurrency }}]
                        </label>
                        <!--
                          @change="sss(mg.id, child.id, $event)"
                        -->
                      </li>
                      <li v-if="mg.menu_ext_group_childs.length == 0"><strong>No menu group childs found!</strong></li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>

    <CCardFooter class="sticky-bottom">
      <CButton
        color="primary"
        :disabled="submitted"
        @click="submit()"
      >
        Submit
      </CButton>
    </CCardFooter>

    <mc-spinner :opacity="0.8" v-show="submitted" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />
  </CCard>

</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength } from "vuelidate/lib/validators"
import _debounce from 'lodash/debounce';  // Import Specific Lodash Functions
import Sortable from "sortablejs";

export default {
  name: 'LinkedMenuItem',

  data() {
    return {
      form: {},
      isEdit: false,
      submitted: false,

      itemName: '',
      restaurantName: '',

      selectedMenuImage: undefined,
      previewMenuImage: undefined,

      // Categories
      availableCategories: [],

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Get previous page
      prevRoute: null,

      // for All Menu Groups and Childs
      // URL params
      activePage: 1,
      itemsPerPage: 10,
      // Response data
      allMenuGroups: [],
      pages: 1,
      to: 0, // Current total
      total: 0,

      // Element Cover Spinner için
      mcSpinnerMarginTop: 0,

      // Search
      search: "",

      // Get Menu Names by Menu Category (changedMenuCategory)
      menuNames: null,
      usetoprice: false,
      isLoading:true,

      days: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
    }
  },

  // Get previous page
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },

  watch: {
    search: function (newQuery, oldQuery) {
      this.debouncedGetMenuGroups()
    }
  },

  created() {
    this.debouncedGetMenuGroups = _debounce(() => {
      this.activePage = 1;
      this.allMenuGroups = [];
      this.getAllMenuGroups();
    }, 1000);
  },

  async mounted() {
    this.submitted = true;
    await this.getRestaurantName();
    await this.getAvailableCategories();

    if(this.itemId) {
      this.form = await this.getForm(this.itemId)
      this.isEdit = true
    }
    else
      this.form = await this.getForm()

    await this.getNextMenuGroups();
    await this.getAllMenuGroups();

        //#region Sortablejs işlemleri
        var itemID, newIndex, itemMgID;
    var el = document.getElementsByTagName("tbody");

    var sortable = Sortable.create(el[0], {
      handle: "svg.c-icon",
      chosenClass: "table-selected",
      animation: 150,
      easing: "cubic-bezier(1, 0, 0, 1)",

      onSort: function (/**Event*/ evt) {
        var itemEl = evt.item; // dragged HTMLElement
        newIndex = evt.newIndex;
        itemID = itemEl.getElementsByTagName("svg")[0].getAttribute("data-smg-id");
        itemMgID = itemEl.getElementsByTagName("svg")[0].getAttribute("data-mg-id");
        // console.log(itemID, newIndex);
      },
      store: {
        /**
         * Save the order of elements. Called onEnd (when the item is dropped).
         * @param {Sortable}  sortable
         */
        set: () =>
          this.storeNewIndexes(
            parseInt(itemID),
            parseInt(itemMgID),
            newIndex
            // newIndex + ((this.activePage - 1) * this.itemsPerPage)
          ),
      },
    });
    //#endregion Sortablejs ---

  },

  computed: {
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
    restaurantId() { return this.$route.params.restaurant_id },
    itemId() { return this.$route.params.id }
  },

  mixins: [validationMixin],

  validations: {
    form: {
      menu_cat: {
        required: (value) => {
          return value !== 0;
        }
      },
      menu_name: { required, minLength: minLength(2) },
      description: { },
      price: { required },
      toPrice: { required },
      menu_image: {
        required: (fileName) => {
          if(!fileName) // Image ekleme zorunlu değil
            return true;

          var filename_arr = fileName.split(".");
          var file_ext = filename_arr[filename_arr.length - 1];

          return ["jpeg", "jpg", "png", "gif", "webp"].indexOf(file_ext) !== -1;
        }
      },
      custom_id: { },
      menugroups: { },
      eol: { },
      internNotes: { },
    },
  },

  methods: {
    //#region Menu Groups (ingredients) Choice
    selectedTypeListing(mg_id, value) {
      var mg = this.form.selectedMenuGroups.find(mg => mg.menuExtGroupId == mg_id && mg.typeListing === value);
      return mg != null;
    },
    changeTypeListing(mg_id, e) {
      var mg = this.form.selectedMenuGroups.find(mg => mg.menuExtGroupId == mg_id);
      if(mg) {
        mg.typeListing = e.target.value;
      }
      else
        this.form.selectedMenuGroups.push({ menuExtGroupId: mg_id, typeListing: e.target.value });
    },

    selectedValidDays(mg_id) {
      var mg = this.form.selectedMenuGroups.find(mg => mg.menuExtGroupId == mg_id);
      return mg?.validDaysOfWeek;
    },
    updateValidDays(mg_id, day) {
      var mg = this.form.selectedMenuGroups.find(mg => mg.menuExtGroupId == mg_id);
      var array = mg?.validDaysOfWeek ?? [];
      array.toggle(day);

      mg.validDaysOfWeek = array.length ? array : null;
    },

    selectMenuGroup(mg_id, value){
      if(value) // checked
        this.form.selectedMenuGroups.push({
          menuExtGroupId: mg_id,
          typeListing: 'SLT',
          validDaysOfWeek: null,
          selected: true,
          Index: this.form.selectedMenuGroups.length > 0
            ? Math.max(...this.form.selectedMenuGroups.map(smg => smg.Index)) + 1
            : 1
          // Index: this.form.selectedMenuGroups.length + 1
        });
      else {    // unchecked
        const index = this.form.selectedMenuGroups.findIndex(mg => mg.menuExtGroupId == mg_id);
        this.form.selectedMenuGroups.splice(index, 1);

        // Silinen menu gruba ait tüm childları da sil.
        var removeChilds = this.form.selectedChilds.filter((child) => {
          return child.menuExtGroupId == mg_id;
        });
        removeChilds.forEach((item) => {
          const index = this.form.selectedChilds.findIndex(child => child.menuExtGroupChildId === item.menuExtGroupChildId);
          this.form.selectedChilds.splice(index, 1);
        });

      }
    },
    isSelectedMenuGroup(mg_id){
      return this.form.selectedMenuGroups.find(mg => mg.menuExtGroupId == mg_id) != null;
    },
    getSelectedMenuGroupId(mg_id) {
      let smg = this.form.selectedMenuGroups.find(smg => smg.menuExtGroupId == mg_id);
      return smg ? smg.id : 0;
    },
    selectingChild(mg_id) {
      var childs_count = this.form.selectedChilds.filter((child) => {
        return child.menuExtGroupId == mg_id;
      }).length;

      (childs_count == 0) && this.selectMenuGroup(mg_id, false);
      (childs_count == 1 && !this.isSelectedMenuGroup(mg_id)) && this.selectMenuGroup(mg_id, true);
    },
    //#endregion

    async getRestaurantName() { // Called when mounted
      await this.$axios
        .get(this.$backend.RESTAURANT.GET_NAME.replace("{id}", this.restaurantId), {
          params: {
            fields: 'usetoprice'
          }
        })
        .then((response) => {
          const { restaurant_name, usetoprice } = response.data;
          this.restaurantName = restaurant_name;
          this.usetoprice = usetoprice;
          this.isLoading = false;
        })
        .catch(() => { this.restaurantName = 'Not Found' })
    },

    async getAvailableCategories() { // Called when mounted
      await this.$axios.get(this.$backend.RESTAURANT_LINKED_CATEGORIES.GET_ALL_BY_RESTAURANT_ID.replace("{restaurant_id}", this.restaurantId))
        .then((response) => (this.availableCategories = response.data));
    },

    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === "");
    },

    onFileChanged(files, event) {
      this.selectedMenuImage = files[0];
      this.$v.form.menu_image.$model = this.selectedMenuImage.name;

      // Yüklenen dosya geçerli kriterleri sağlıyor mu? (validations)
      if (this.checkIfValid("menu_image"))
        this.previewMenuImage = URL.createObjectURL(this.selectedMenuImage);
      else {
        this.selectedMenuImage = undefined;
        this.previewMenuImage = undefined;
        this.$v.form.menu_image.$model = '';
      }
    },

    async submit () {
      window.scrollTo(0,0);

      if (!this.isValid) {
        this.validate();
        return;
      }

      if (this.isEdit) {
        await this.update()
      } else {
        await this.store()
      }
    },

    async uploadImage() {
      if (!this.selectedMenuImage) {
        return;
      }

      // Eğer resim güncellendiyse...
      const formData = new FormData();
      formData.append('menu_image', this.selectedMenuImage, this.selectedMenuImage.name);
      formData.append('menu_name', this.form.menu_name)

      await this.$axios.post(this.$backend.RESTAURANT_LINKED_MENUS.SAVE_MENU_IMAGE.replace("{restaurant_id}", this.restaurantId), formData)
        .then((response) => {
          //Api'den gelen resim yolunu hidden inputa ata (response varsa valid olacak.)
          this.form.menu_image = response.data.data.menu_image;
          this.selectedMenuImage = null;
        })
    },

    async store() {
      this.submitted = true;


      try {
        await this.uploadImage();
        this.form.menugroups = this.form.selectedMenuGroups.length;

        const url = this.$backend.RESTAURANT_LINKED_MENUS.STORE.replace("{restaurant_id}", this.restaurantId);
        const response = await this.$axios.post(url, this.form);

        this.successAlertMessage = { itemName: response.data.menu_name, message: 'linked menu added.' }
        this.dismissSuccessAlert = this.dismissSecs
        this.reset();
      } catch (error) {
        this.errorAlertMessage = error.response.data
        this.showErrorAlert = true
      } finally {
        this.submitted = false;
      }
    },

    async update() {
      this.submitted = true;

      try {
        await this.uploadImage();
        this.form.menugroups = this.form.selectedMenuGroups.length;

        const url = this.$backend.RESTAURANT_LINKED_MENUS.UPDATE.replace("{restaurant_id}", this.restaurantId).replace("{id}", this.itemId);
        const response = await this.$axios.put(url, this.form);

        this.itemName = response.data.menu_name
        this.successAlertMessage = { itemName: this.itemName, message: 'linked menu updated.' }
        this.dismissSuccessAlert = this.dismissSecs
        this.showErrorAlert = false

        // Menu grupları yenile.
        this.activePage = 1;
        this.allMenuGroups = [];
        this.getAllMenuGroups();
      } catch (error) {
        this.errorAlertMessage = this.$store.state.errors
        this.showErrorAlert = true
      } finally {
        this.submitted = false;
      }
    },

    validate () {
      this.$v.$touch()
    },

    async reset () {
      this.form = await this.getForm()
      this.submitted = false
      this.showErrorAlert = false
      this.selectedMenuImage = undefined
      this.previewMenuImage = undefined
      this.$v.$reset()
    },

    async getForm (id = null) {
      var form = {
        menu_cat: 0,
        menu_name: '',
        description: '',
        price: 0.00,
        toPrice: 0.00,
        menu_image: '',
        // index: 0,
        custom_id: null,
        menugroups: 0,
        eol: true,
        internNotes: '',

        selectedMenuGroups: [],
        selectedChilds: [],

        restaurant_id: this.restaurantId
      };

      if(id) {
        await this.$axios.get(this.$backend.RESTAURANT_LINKED_MENUS.SHOW.replace("{restaurant_id}", this.restaurantId).replace("{id}", id))
          .then((res) => {
            const menu = res.data.data;

            form.menu_cat = menu.menu_cat;
            form.menu_name = menu.menu_name;
            form.description = menu.description;
            form.price = menu.price;
            form.toPrice = menu.toPrice;
            form.custom_id = menu.custom_id;
            form.menugroups = menu.menugroups;

            form.eol = menu.eol == 0;
            form.internNotes = menu.internNotes;

            // Menu Image
            if(menu.menu_image_url) {
              const menu_image = menu.menu_image_url;

              form.menu_image = menu_image;
              this.previewMenuImage = menu_image;
            }

            this.itemName = menu.menu_name

            // selectedMenuGroups
            menu.menu_linked_ext_groups.forEach((item) => {
              form.selectedMenuGroups.push({
                menuExtGroupId: item.mnuextgrId,
                typeListing: item.TypeListing,
                validDaysOfWeek: item.valid_days_of_week,
                selected: true,
                Index: item.Index,
              });

              // selectedChilds
              item.menu_linked_ext_gr_childs.forEach((child) => {
                form.selectedChilds.push({
                  menuExtGroupId: item.mnuextgrId,
                  menuExtGroupChildId: child.megcId,
                });
              });
            });

            // Get Menu Names by Menu Category (changedMenuCategory)
            this.changedMenuCategory(menu.menu_cat);
          })
          .catch(() => {
            this.$toast.error(`${this.$store.state.errors.message}`);
            this.$router.push({ name: 'RestaurantLinkedMenus' })
          })
      }
      return form;
    },

    async getAllMenuGroups() { // Called when mounted
      this.submitted = true;

      const selectedExtGroup = this.form.selectedMenuGroups.map((group) => group.menuExtGroupId);

      var url = new URL(this.$backend.MENU_GROUPS.GET_ALL_WITH_CHILDS),
        params = {
          page: this.activePage,
          // itemsPerPage: this.itemsPerPage,
          itemsPerPage: (Math.floor(selectedExtGroup.length / 5) + 1) * 5,
          selectedExtGroup: selectedExtGroup,
          search: this.search
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.allMenuGroups = this.allMenuGroups.concat(items.data);
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.to = items.to;
            this.total = items.total;
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },

    async getNextMenuGroups() { // Called when mounted
      window.onscroll = () => {
        this.mcSpinnerMarginTop = document.documentElement.scrollTop;
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;

        if (bottomOfWindow) {
          if (this.activePage < this.pages) {
            this.activePage++;
            this.getAllMenuGroups();
          }
        }
      }
    },

    async changedMenuCategory(category_id) {
      this.submitted = true;

      await this.$axios.get(this.$backend.RESTAURANT_LINKED_MENUS.LIST.replace("{restaurant_id}", this.restaurantId), {params: {category_id: category_id}})
        .then((response) => {
          this.menuNames = response.data.map((m) => (m.menu_name)).join(', ');
        })
        .finally(() => {
          this.submitted = false;
        });
    },

    storeNewIndexes(itemID, itemMgID, newIndex) {
      // console.log(itemID, itemMgID, newIndex);
      // console.log(this.form.selectedMenuGroups);
      // console.log(this.allMenuGroups);
      // return

      if(isNaN(itemID) || isNaN(newIndex))
        return;

      // Dizi index'i 0'dan başlıyor, ancak bizim 0. elemanın index'i 1'den başlıyor.
      newIndex++;

      // For Front-End
      var item = this.form.selectedMenuGroups.find(item => item.id == itemID && item.menuExtGroupId == itemMgID);
      var oldIndex = item.Index;
      if (newIndex > oldIndex) {
        this.form.selectedMenuGroups.filter((item) => {
          return item.Index > oldIndex && item.Index <= newIndex;
        }).forEach((value, index) => { value.Index--; });
      }
      else if (newIndex < oldIndex) {
        this.form.selectedMenuGroups.filter((item) => {
          return item.Index < oldIndex && item.Index >= newIndex;
        }).forEach((value, index) => { value.Index++; });
      }
      item.Index = newIndex;
    },
  }
}
</script>

<style scoped>
div.sticky-search {
  position: -webkit-sticky;
  position: sticky;
  z-index: 98;
  top: 100px;

  margin-top: 1.5rem;
  padding: 0.5rem;
  background-color: #ebedef;
  border-radius: 5px;
}

ul {
  flex-wrap: wrap;
  display: flex;

  list-style-type: none;
  background-color: #e6e6fa;
  border-radius: 5px;
  margin: 0;
  padding: 10px 20px;
}
ul li {
  flex: 0 0 25%;
}

.disable-item {
  pointer-events: none;
  opacity: 0.6;
}
.enable-item {
  pointer-events: auto;
  opacity: 1;
}
.enable-item, .move-item {
  vertical-align: top !important;
}
.selected-item {
  border-left: 5px solid #2eb85c;
}
label.for-checkbox, label.for-checkbox > input {
  cursor: pointer;
}
</style>

<style>
.custom-checkbox-label {
  font-size: 18px;
  font-weight: 500;
}
.custom-control-input:checked ~ .custom-checkbox-label::before {
  border-color: #2eb85c;
  background-color: #2eb85c;
}

.day-group .form-check {
  padding-left: 0 !important;
}
.day-group .form-check-input {
  display: none;
}
.day-group .form-check-label {
  font-weight: normal;
  text-align: center;
  width: 32px;
  cursor: pointer;
  padding: 0.275rem;
  border: 1px solid rgba(60,60,60,.26);
  border-radius: 50%;
}
.day-group input[type=checkbox]:checked + .form-check-label {
  background-color: #d5f1de;
  border-color: #c4ebd1;
}
</style>
